<template>
    <v-navigation-drawer
        v-model="Sidebar_drawer"
        :dark="SidebarColor !== 'white'"
        :color="SidebarColor"
        mobile-breakpoint="960"
        clipped
        :right="$vuetify.rtl"
        mini-variant-width="70"
        :expand-on-hover="expandOnHover"
        app
        id="main-sidebar"
        v-bar
    >

        <v-list expand nav class="mt-1">
            <template v-for="(item, i) in items">
                <!---If Sidebar Caption -->
                <v-row v-if="item.header" :key="item.header" align="center">
                    <v-col cols="12">
                        <v-subheader v-if="item.header" class="d-block text-truncate">{{ item.header }}</v-subheader>
                    </v-col>
                </v-row>
                <!---If Sidebar Caption -->
                <BaseItemGroup v-else-if="item.children" :key="`group-${i}`" :item="item"></BaseItemGroup>

                <BaseItem v-else :key="`item-${i}`" :item="item" />
            </template>
            <!---Sidebar Items -->
        </v-list>
        <template v-if="$store.getters.isCpaNetworkManager">
            <v-list v-for="(groupItems, index) in cpaNetworkManagerItems" :key="index" expand nav class="mt-1">
                <template v-for="(item, i) in groupItems">
                    <!---If Sidebar Caption -->
                    <v-row v-if="item.header" :key="item.header" align="center">
                        <v-col cols="12">
                            <v-subheader v-if="item.header" class="d-block text-truncate">{{ item.header }}</v-subheader>
                        </v-col>
                    </v-row>
                    <!---If Sidebar Caption -->
                    <BaseItemGroup v-else-if="item.children" :key="`group-${i}`" :item="item"></BaseItemGroup>

                    <BaseItem v-else :key="`item-${i}`" :item="item" />
                </template>
                <!---Sidebar Items -->
            </v-list>
        </template>

        <template v-if="$store.getters.isMaster">
            <v-list expand nav class="mt-1">
                <template v-for="(item, i) in masterItems">
                    <!---If Sidebar Caption -->
                    <v-row v-if="item.header" :key="item.header" align="center">
                        <v-col cols="12">
                            <v-subheader v-if="item.header" class="d-block text-truncate">{{ item.header }}</v-subheader>
                        </v-col>
                    </v-row>
                    <!---If Sidebar Caption -->
                    <BaseItemGroup v-else-if="item.children" :key="`group-${i}`" :item="item"></BaseItemGroup>

                    <BaseItem v-else :key="`item-${i}`" :item="item" />
                </template>
            </v-list>
        </template>

        <template v-if="$store.getters.whiteLabel === 'Leads'">

        </template>
        <div v-else class="text-center mt-4">
            <v-img class="d-inline-block" width="120px" max-width="100%" src="@/assets/images/web-cpaider-support.svg" />
            <br>
            <h5>{{ trans('pages.supportHeader') }}</h5>
            Telegram: <a href="tg://resolve?domain=cpaider"> <span class="icon linearicons-location"></span>@cpaider</a>
        </div>
    </v-navigation-drawer>
</template>

<script>
    import { mapState } from "vuex";
    import SidebarItems from "./SidebarItems";
    import CpaNetworkManagerSidebarItems from "./CpaNetworkManagerSidebarItems";
    import MasterSidebarItems from "@/layouts/full-layout/sidebar/MasterSidebarItems";
    export default {
        name: "Sidebar",
        props: {
            expandOnHover: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            itemsRaw: SidebarItems,
            masterItemsRaw: MasterSidebarItems,
            cpaNetworkManagerItemsRaw: CpaNetworkManagerSidebarItems,
        }),
        computed: {
            ...mapState(["SidebarColor", "SidebarBg"]),
            Sidebar_drawer: {
                get() {
                    return this.$store.state.Sidebar_drawer;
                },
                set(val) {
                    this.$store.commit("SET_SIDEBAR_DRAWER", val);
                }
            },
            items() {
                let items = _.filter(this.itemsRaw, (item) => {
                    if (this.$store.getters.vendorId){
                        if (item.hide && item.hide.vendor){
                            return false;
                        }
                    }
                    if (this.$store.getters.vendorAlias){
                        if (item.hide && item.hide.vendorAlias && item.hide.vendorAlias.indexOf(this.$store.getters.vendorAlias) !== -1){
                            return false;
                        }
                    }
                    return true;
                });
                return _.map(items, (item) => {
                    if (item.header){
                        item.header = this.trans(item.header);
                    }
                    if (item.title){
                        item.title = this.trans(item.title);
                    }
                    return item;
                })
            },
            masterItems() {
                return _.map(this.masterItemsRaw, (item) => {
                    if (item.header){
                        item.header = this.trans(item.header);
                    }
                    if (item.title){
                        item.title = this.trans(item.title);
                    }
                    return item;
                })
            },
            cpaNetworkManagerItems(){
                let items = [];
                if (this.$store.getters.isCpaNetworkManager){
                    _.each(this.$store.getters.user.cpa_network_managers, (manager) => {
                        let group = [];
                        group.push({header: this.trans('manage.menuHeader', {label: manager.cpa_network.label})});
                        group = _.concat(group, _.map(this.cpaNetworkManagerItemsRaw, (item) => {
                            if (item.header){
                                item.header = this.trans(item.header);
                            }
                            if (item.title){
                                item.title = this.trans(item.title);
                            }
                            if (item.to){
                                item.to.params = {id: manager.cpa_network_id};
                            }
                            return item;
                        }));
                        items.push(group);
                    })
                }
                return items;
            }
        },
        watch: {
            "$vuetify.breakpoint.smAndDown"(val) {
                this.$emit("update:expandOnHover", !val);
            }
        },

        methods: {}
    };
</script>
<style lang="scss">
    #main-sidebar{
        box-shadow:1px 0 20px rgba(0,0,0,.08);
        -webkit-box-shadow:1px 0 20px rgba(0,0,0,.08);
        .v-navigation-drawer__border{
            display: none;
        }
        .v-list {
            padding: 8px 15px;
        }
        .v-list-item{
            &__icon--text,
            &__icon:first-child{
                justify-content: center;
                text-align: center;
                width: 20px;

            }
        }
        .profile-bg{
            background:url('../../../assets/images/user-info.jpg') no-repeat;
            .v-avatar{
                padding:45px 0;
            }
        }


    }
</style>
