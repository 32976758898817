export default [
    {
        icon: 'mdi-view-dashboard',
        title: "router.user.dashboard",
        to: {name: 'user.dashboard'},
    },
    {
        icon: 'mdi-animation',
        title: "router.user.offers",
        to: {name: 'user.offers'},
    },
    {
        icon: 'mdi-domain',
        title: "router.user.domains",
        to: {name: 'user.domains'},
    },
    {
        icon: 'mdi-link-variant',
        title: "router.user.links",
        to: {name: 'user.links'},
    },
    {
        icon: 'mdi-lan',
        title: "router.user.cpaNetworks",
        to: {name: 'user.cpaNetworks'},
        hide: {
            vendorAlias: ['leads', 'workle'],
        }
    },
    {
        icon: 'mdi-magnify',
        title: "router.user.landingDesigns",
        to: {name: 'user.landingDesigns'},
    },
    {
        icon: 'mdi-monitor-multiple',
        title: "router.user.landings",
        to: {name: 'user.landings'},
    },
    {
        icon: 'mdi-credit-card-multiple',
        title: "router.user.billing",
        to: {name: 'user.billing'},
        hide: {
            vendorAlias: ['saleads', 'leads', 'workle'],
        }
    },
    // {
    //     icon: 'mdi-spider-web',
    //     title: "router.user.integrations",
    //     to: {name: 'user.integrations'},
    //     hide: {
    //         vendorAlias: ['saleads', 'leads'],
    //     }
    // },
]
