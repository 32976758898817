export default [
    {
        header: "master.menuHeader",
    },
    {
        icon: 'mdi-animation',
        title: "router.master.users",
        to: {name: 'master.users'},
    },
    {
        icon: 'mdi-code-json',
        title: "router.master.apiSettings",
        to: {name: 'master.apiSettings'},
    },
]
