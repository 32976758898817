<template>
  <v-app-bar app clipped-left clipped-right color="primary" dark>
      <!---/Toggle sidebar part -->
      <div @click="showhideLogo">
          <v-app-bar-nav-icon
              @click="$vuetify.breakpoint.smAndDown ? setSidebarDrawer(!Sidebar_drawer) : $emit('input', !value)"
          />
      </div>
      <!---/Toggle sidebar part -->
    <!---Logo part -->
    <v-toolbar-title class="align-center d-flex">
      <span class="logo-icon">
        <img v-if="$store.getters.whiteLabel === 'Leads'" src="@/assets/images/web-leads.su2.svg" style="max-width: 150px; margin-top: 34px; margin-left: 10px;" />
        <img v-else-if="$store.getters.whiteLabel === 'Saleads'" src="@/assets/images/web-saleads.svg" style="max-width: 150px; margin-top: 34px; margin-left: 10px;" />
        <img v-else-if="$store.getters.whiteLabel === 'Workle'" src="@/assets/images/web-workle.svg" style="max-width: 150px; margin-top: 34px; margin-left: 10px;" />
        <img v-else src="@/assets/logo.svg" style="max-width: 150px; margin-top: 34px; margin-left: 10px;" />
      </span>
<!--      <span class="logo-text ml-2" :class="`${showLogo ? '' : 'hidelogo'}`">-->
<!--        <img src="@/assets/logo.svg" class="mt-2" style="max-width: 160px;"/>-->
<!--      </span>-->
    </v-toolbar-title>
    <!---Logo part -->
    <v-spacer />
    <lang-switcher class="mr-4"></lang-switcher>
    <!---right part -->
    <!---User -->
    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-1">
          <v-avatar size="40">
            <v-icon>mdi mdi-account</v-icon>
          </v-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item two-line v-if="$store.getters.user">
            <v-list-item-content>
                <v-list-item-subtitle>Учетная запись</v-list-item-subtitle>
                <v-list-item-title>{{ $store.getters.user.email }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
            <v-list-item-title>{{ trans('auth.logout') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!---User -->
  </v-app-bar>
</template>
<script>
// Utilities
import { mapState, mapMutations } from "vuex";
import LangSwitcher from "@/components/commonComponents/LangSwitcher";

export default {
  name: "Header",

  components: {LangSwitcher},

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showLogo: true,
    showSearch: false,
    notifications: [
      {
        title: "Launch Admin",
        iconbg: "error",
        icon: "mdi-link-variant",
        desc: "Just see the my new admin!",
        time: "9:30AM"
      },
      {
        title: "Event today",
        iconbg: "success",
        icon: "mdi-calendar-check",
        desc: "Just a reminder that you have event",
        time: "10:30AM"
      },
      {
        title: "Settings",
        iconbg: "info",
        icon: "mdi-cog",
        desc: "You can customize this template as you want",
        time: "11:30AM"
      },
      {
        title: "Pavan Kumar",
        iconbg: "indigo",
        icon: "mdi-account",
        desc: "Sent you an notification",
        time: "12:30AM"
      }
    ],
    messages: [
      {
        title: "Sonu Nigam",
        avatar: "1",
        avatarstatus: "success",
        desc: "Singing Show tonigh at 9pm!",
        time: "9:30AM"
      },
      {
        title: "Sonu Nigam",
        avatar: "2",
        avatarstatus: "error",
        desc: "The greate malody songs ever sung",
        time: "10:30AM"
      },
      {
        title: "Arijit singh",
        avatar: "3",
        avatarstatus: "warning",
        desc: "You can customize this template as you want",
        time: "11:30AM"
      },
      {
        title: "Pavan Kumar",
        avatar: "4",
        avatarstatus: "success",
        desc: "Sent you an notification",
        time: "12:30AM"
      }
    ],
    userprofile: [
      { title: "Logout" }
    ],
    href() {
      return undefined;
    },

  }),

  computed: {
    ...mapState(["Sidebar_drawer"])
  },

  methods: {
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER"
    }),
    showhideLogo: function() {
      this.showLogo = !this.showLogo;
    },
    searchbox: function() {
      this.showSearch = !this.showSearch;
    },
    logout() {
      this.$store.dispatch('logout');
    }
  }
};
</script>

<style lang="scss">
  .v-application #main-sidebar.theme--dark.white{
    background:#363636!important;
  }
  .hidelogo{
    display: none;
  }
  .searchinput{
    position: absolute;
    width: 100%;
    margin: 0;
    left: 0;
    z-index: 10;
    padding: 0 15px;
  }
  .descpart{
    max-width:220px;
  }
</style>
